/* eslint-disable react/no-unknown-property */
import Chance from 'chance'
import { useAnimation } from 'framer-motion'
import React, { createRef, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { FadeInWhenVisible } from '../../../components/FadeInWhenVisible'
import useWindowSize from '../../../hooks/useWindowSize'
import { Point2D } from '../lib/2D'
import { BubbleAnimationDef, BubbleEventCallback, SVGPathElement as EnhancedSVGPath, animate } from '../lib/animation'
import styles from './Anim.module.scss'

export type TeamBubble = {
  content: {
    media: {
      filename: string
    }
  }
}

// Define the bubble animation table
const smallerScreenAnimationDefs: BubbleAnimationDef[] = [
  {
    startPos: new Point2D(700, 330),
    endPos: new Point2D(860, 180),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 3790,
  },
  {
    startPos: new Point2D(860, 770),
    endPos: new Point2D(1380, 1170),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 3295,
  },
  {
    startPos: new Point2D(600, 990),
    endPos: new Point2D(90, 920),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 2870,
  },
  {
    startPos: new Point2D(300, 1000),
    endPos: new Point2D(-163, 1150),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 2585,
  },
  {
    startPos: new Point2D(375, 280),
    endPos: new Point2D(430, 315),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 1820,
  },
  {
    startPos: new Point2D(440, 620),
    endPos: new Point2D(520, 1255),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 1320,
  },
]

const largeScreenAnimationDefs: BubbleAnimationDef[] = [
  {
    startPos: new Point2D(700, 330),
    endPos: new Point2D(2710, 185),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 5790,
  },
  {
    startPos: new Point2D(860, 770),
    endPos: new Point2D(2730, 1145),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 5295,
  },
  {
    startPos: new Point2D(600, 990),
    endPos: new Point2D(1450, 920),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 4870,
  },
  {
    startPos: new Point2D(300, 1000),
    endPos: new Point2D(1180, 1145),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 4585,
  },
  {
    startPos: new Point2D(375, 280),
    endPos: new Point2D(1760, 315),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 3820,
  },
  {
    startPos: new Point2D(440, 620),
    endPos: new Point2D(1890, 1250),
    anim: [
      { opacity: 0 },
      { opacity: 1 },
    ],
    timing: {
      duration: 1500,
      iterations: 1,
      fill: 'forwards',
    },
    trigger: 3320,
  },
]

function Anim({ team } : { team: string[] }) {
  const [isMounted, setIsMounted] = useState(false)
  const { width=0 } = useWindowSize()
  const controls = useAnimation()
  const [anchorRef, isInView] = useInView({
    root: null,
    threshold: 0,
    triggerOnce: true,
  })

  useEffect(() => {
    if (isInView) {
      controls.start('visible')
    }

    if (!isInView) {
      controls.start('hidden')
    }
  })

  const emptyRef = useRef(null)

  if (!team || team?.length === 0) {
    return null
  }


  const [images, setImages] = useState(team.map(url => `/_next/image?url=${encodeURIComponent(url)}&w=640&q=75`))
  const chance = new Chance(Math.round(Math.random() * images.length))
  const viewportRef = useRef<SVGSVGElement>(null)
  const pathRef = useRef<SVGPathElement>(null)
  const bubbleRefs: React.RefObject<SVGImageElement>[] = []
  const isLargeDesktop = width >= 2500
  const animationDefs = isLargeDesktop ? largeScreenAnimationDefs : smallerScreenAnimationDefs

  for(const key in animationDefs) {
    bubbleRefs[key] = createRef()
  }

  const handleAnimationCheckpoint: BubbleEventCallback =
    (key: number, animationDef: BubbleAnimationDef) => {
      bubbleRefs?.[key]?.current?.animate(
        animationDef.anim as Keyframe[],
        animationDef.timing,
      )
    }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleAnimationComplete = () => {
    setInterval(() => {
      const index = Math.round(Math.random() * (bubbleRefs.length - 1))

      const currentRef = bubbleRefs[index]?.current

      if (currentRef) {
        currentRef.
          animate(
            [{ opacity: 0 }],
            {
              duration: 1750,
              iterations: 1,
              fill: 'forwards',
            },
          ).finished.then(() => {
            const imageIndex = images.indexOf(currentRef.href.baseVal)

            const newImageIndex = Math.round(Math.random() * (images.length - 7)) + 6
            // @ts-ignore
            const tempImage: string = images[newImageIndex] || images[0]

            // @ts-ignore
            images[newImageIndex] = images[imageIndex]
            // @ts-ignore
            images[imageIndex] = tempImage

            const svgImageLoad = new Promise((resolve, reject) => {
              currentRef.addEventListener('error', (error) => reject(error))
              currentRef.addEventListener('load', () => resolve(tempImage))
              currentRef.setAttribute('href', tempImage)
            })

            return svgImageLoad.then(() => {
              return currentRef.animate(
                [
                  { opacity: 1 },
                ],
                {
                  duration: 1750,
                  iterations: 1,
                  fill: 'forwards',
                },
              )
            })
          }).catch(error => console.error(error))
      }
    }, 3000)
  }

  // Initialize the animation
  useEffect(() => {
    if (isMounted && isInView && viewportRef.current && pathRef.current) {
      animate(
        viewportRef.current,
        pathRef.current as unknown as EnhancedSVGPath,
        animationDefs,
        handleAnimationCheckpoint,
        handleAnimationComplete,
      )
    }
  }, [isMounted, isInView, viewportRef, pathRef])

  useEffect(() => {
    setImages(chance.shuffle(images))

    async function preloadImages() {
      await Promise.all(images.map(url => {
        return new Promise((resolve, reject) => {
          const img = new Image()

          img.src = url
          img.addEventListener('error', (error) => {
            reject(error)
          })
          img.addEventListener('load', () => {
            resolve(img)
          })
        })
      }))
    }

    preloadImages()
    setIsMounted(true)
  }, [])

  return (
    <FadeInWhenVisible animation={'easyFadeInTwoColStaggered'}>
      <div ref={isMounted ? anchorRef : emptyRef} className={styles.svgViewportWrapper}>
        <svg ref={viewportRef} className={styles.svgViewport} viewBox={isLargeDesktop ? '0 0 5199.739 1260.314' : '0 0 3110 1329.9957786952173'} preserveAspectRatio="none">
          <defs>
            <clipPath id="clip-path">
              <path d="M233.6,177.911c3.525-9.052,7.993-17.78,9.585-29.054,3.5-34.562-2.485-67.563-20.6-90.942C193.7,20.637,149.575-8.383,101.147,2.2,59.1,11.718,30.7,38.868,13.3,67.228-6.85,100.065-3.4,144.01,18.914,182.683a128.5,128.5,0,0,0,47.32,41.3A164.811,164.811,0,0,0,87.9,233.294C140.413,244.913,201.883,227.373,233.6,177.911Z"
                transform={isLargeDesktop ? `matrix(0.602, 0.799, -0.799, 0.602, ${animationDefs?.[5]?.endPos.x + 145.809}, ${animationDefs?.[5]?.endPos.y -603.393})` :`matrix(0.602, 0.799, -0.799, 0.602, ${animationDefs?.[5]?.endPos.x + 145.809}, ${animationDefs?.[5]?.endPos.y -603.393})` } fill="#ffffff"/>
            </clipPath>
            <clipPath id="clip-path-2">
              <path d="M793.077,538.871c4.253-10.922,9.644-21.453,11.565-35.055,4.221-41.7-3-81.52-24.851-109.728-34.852-44.979-88.1-79.994-146.528-67.22-50.733,11.479-85,44.237-106,78.457-24.308,39.62-20.151,92.643,6.778,139.3,15.126,21.868,34.464,38.056,57.1,49.835a198.825,198.825,0,0,0,26.146,11.231C680.639,619.714,754.807,598.551,793.077,538.871Z"
                transform={isLargeDesktop ? `translate(${animationDefs?.[4]?.endPos.x-511.22} ${animationDefs?.[4]?.endPos.y-324.208})` : `translate(${animationDefs?.[4]?.endPos.x-511.22} ${animationDefs?.[4]?.endPos.y-324.208})`} fill="#ffffff"/>
            </clipPath>
            <clipPath id="clip-path-3">
              <path d="M187.995,143.178c2.837-7.285,6.433-14.309,7.714-23.382,2.816-27.815-2-54.373-16.575-73.187-23.245-30-58.759-53.355-97.733-44.835C47.562,9.43,24.7,31.28,10.7,54.1-5.513,80.529-2.74,115.9,15.221,147.018A103.415,103.415,0,0,0,53.3,180.257a132.646,132.646,0,0,0,17.439,7.491C113,197.1,162.47,182.983,187.995,143.178Z"
                transform={isLargeDesktop ? `translate(${animationDefs?.[3]?.endPos.x + 870.829} ${animationDefs?.[3]?.endPos.y-17.259}) rotate(133)` : `translate(${animationDefs?.[3]?.endPos.x + 870.829} ${animationDefs?.[3]?.endPos.y-17.259}) rotate(133)`} fill="#ffffff" />
            </clipPath>
            <clipPath id="clip-path-4">
              <path d="M187,142.423c2.822-7.247,6.4-14.234,7.673-23.258,2.8-27.668-1.989-54.086-16.488-72.8C155.067,16.52,119.74-6.711,80.972,1.764c-33.66,7.616-56.4,29.35-70.328,52.054-16.128,26.287-13.37,61.467,4.5,92.425a102.87,102.87,0,0,0,37.882,33.064,131.93,131.93,0,0,0,17.347,7.452C112.4,196.061,161.614,182.02,187,142.423Z"
                transform={isLargeDesktop ? `translate(${animationDefs?.[2]?.endPos.x + 890.895} ${animationDefs?.[2]?.endPos.y + 189.663}) rotate(149)` : `translate(${animationDefs?.[2]?.endPos.x + 890.895} ${animationDefs?.[2]?.endPos.y + 189.663}) rotate(149)`} fill="#ffffff"/>
            </clipPath>
            <clipPath id="clip-path-5">
              <path d="M192.77,146.815c2.909-7.47,6.6-14.673,7.91-23.976,2.887-28.521-2.051-55.754-17-75.046C159.848,17.03,123.432-6.918,83.468,1.819c-34.7,7.851-58.137,30.255-72.5,53.659-16.625,27.1-13.782,63.362,4.636,95.275a106.041,106.041,0,0,0,39.049,34.084,136,136,0,0,0,17.882,7.681C115.871,202.106,166.6,187.632,192.77,146.815Z"
                transform={isLargeDesktop ? `translate(${animationDefs?.[1]?.endPos.x-431.185} ${animationDefs?.[1]?.endPos.y-392.202}) rotate(8)` : `translate(${animationDefs?.[1]?.endPos.x-431.185} ${animationDefs?.[1]?.endPos.y-392.202}) rotate(8)`} fill="#fff" />
            </clipPath>
            <clipPath id="clip-path-6">
              <path d="M227.8,173.492c3.437-8.827,7.795-17.339,9.347-28.332,3.412-33.7-2.423-65.885-20.085-88.683C188.893,20.124,145.86-8.175,98.635,2.149c-41,9.277-68.7,35.753-85.669,63.409C-6.68,97.579-3.32,140.433,18.444,178.145a125.311,125.311,0,0,0,46.145,40.277A160.711,160.711,0,0,0,85.721,227.5C136.925,238.83,196.868,221.726,227.8,173.492Z"
                transform={isLargeDesktop ? `translate(${animationDefs?.[0]?.endPos.x -590.737} ${animationDefs?.[0]?.endPos.y +332.92}) rotate(-45)` : `translate(${animationDefs?.[0]?.endPos.x -90.737} ${animationDefs?.[0]?.endPos.y +332.92}) rotate(-45)`} fill="#ffffff"/>
            </clipPath>
          </defs>
          <image
            href={images[0]}
            width="280"
            ref={bubbleRefs[1]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[1]?.endPos.x} ${animationDefs?.[1]?.startPos.y}` : `${animationDefs?.[1]?.startPos.x} ${animationDefs?.[1]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[1]?.endPos.x - 480 : animationDefs?.[1]?.endPos.x - 480}
            y={isLargeDesktop ? animationDefs?.[1]?.endPos.y - 380: animationDefs?.[1]?.endPos.y - 390}
            clipPath="url(#clip-path-5)"
          />
          <image
            href={images[1]}
            width="220"
            ref={bubbleRefs[3]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[3]?.endPos.x} ${animationDefs?.[3]?.startPos.y}` : `${animationDefs?.[3]?.startPos.x} ${animationDefs?.[3]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[3]?.endPos.x + 625 : animationDefs?.[3]?.endPos.x + 625}
            y={isLargeDesktop ? animationDefs?.[3]?.endPos.y - 110 : animationDefs?.[3]?.endPos.y - 110}
            clipPath="url(#clip-path-3)"
          />
          <image
            href={images[2]}
            width="330"
            ref={bubbleRefs[4]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[4]?.endPos.x} ${animationDefs?.[4]?.startPos.y}` : `${animationDefs?.[4]?.startPos.x} ${animationDefs?.[4]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[4]?.endPos.x : animationDefs?.[4]?.endPos.x - 5}
            y={isLargeDesktop ? animationDefs?.[4]?.endPos.y : animationDefs?.[4]?.endPos.y}
            clipPath="url(#clip-path-2)"
          />

          {isLargeDesktop ? (
            <path
              ref={pathRef}
              className={styles.mainPath}
              d="m 2.022,907.394 c 939.1,-486.84 1601.518,274.014 1441.9,337.862 -159.618,63.848 -99.435,-118.719 -99.435,-118.719 50.844,-155.209 176.617,-243.518 334.5,-270.278 157.883,-26.76 82.959,46.134 82.959,46.134 -22.721,24.152 -57,37.826 -80.124,42.013 -100.632,18.226 -137.436,-120.874 -72.409,-157.723 80.28,-45.492 329.14,69.576 329.14,69.576 0,0 107.9,82.039 107.9,-196.267 0,-278.306 -148.031,-191.756 -284.508,83.874 -136.477,275.63 -93.672,468.142 200.13,445.692 293.802,-22.45 403.035,-156.919 486.667,-317.728 51.517,-99.057 61.734,-160.93 22.491,-235.653 -39.243,-74.723 -72.919,-87.937 -124.3,-137.262 -133.8,-128.449 53.646,-247.14 233.02,-261.6 179.374,-14.46 308.249,27.707 436.014,16.094 127.765,-11.613 334.163,-75.672 351.5,-155.178 17.337,-79.506 -44.211,-67.988 -84.817,-62.6 -40.606,5.388 -36.065,80.586 14.757,87.233 50.822,6.647 48.1,-58.006 70.06,-87.233 21.96,-29.227 -12.677,-39.254 -45.122,-31.718 -32.445,7.536 -62.472,116.013 89.524,118.953 151.996,2.94 182.944,-23.667 424.636,-72.036 241.692,-48.369 332.665,-17.306 457.491,0 0,0 736.047,191.484 903.647,-37.3"
            />
          ) : (
            <path
              ref={pathRef}
              className={styles.mainPath}
              d="m 1.901,1127.037 c 50.844,-155.209 176.617,-243.518 334.5,-270.278 157.883,-26.76 82.959,46.134 82.959,46.134 -22.721,24.152 -57,37.826 -80.124,42.013 C 238.604,963.132 201.8,824.032 266.827,787.183 c 80.28,-45.492 329.14,69.576 329.14,69.576 0,0 107.9,82.039 107.9,-196.267 0,-278.306 -148.029,-191.756 -284.506,83.874 -136.477,275.63 -93.672,468.142 200.13,445.692 293.802,-22.45 403.035,-156.919 486.667,-317.728 51.517,-99.057 61.734,-160.93 22.491,-235.653 -39.243,-74.723 -72.919,-87.937 -124.3,-137.262 -133.8,-128.449 53.646,-247.14 233.02,-261.6 179.374,-14.46 308.249,27.707 436.014,16.094 127.765,-11.613 334.163,-75.672 351.5,-155.178 17.337,-79.506 -44.211,-67.988 -84.817,-62.6 -40.606,5.388 -36.065,80.586 14.757,87.233 50.822,6.647 48.1,-58.006 70.06,-87.233 21.96,-29.227 -12.677,-39.254 -45.122,-31.718 -32.445,7.536 -62.472,116.013 89.524,118.951 151.996,2.938 182.944,-23.667 424.636,-72.036 241.692,-48.369 332.665,-17.306 457.491,0"
            />
          )}

          <image
            href={images[3]}
            width="320"
            ref={bubbleRefs[0]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[0]?.endPos.x} ${animationDefs?.[0]?.startPos.y}` : `${animationDefs?.[0]?.startPos.x} ${animationDefs?.[0]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[0]?.endPos.x - 580 : animationDefs?.[0]?.endPos.x - 80}
            y={isLargeDesktop ? animationDefs?.[0]?.endPos.y + 215 : animationDefs?.[0]?.endPos.y + 210}
            clipPath="url(#clip-path-6)"
          />
          <image
            href={images[4]}
            width="230"
            ref={bubbleRefs[2]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[2]?.endPos.x} ${animationDefs?.[2]?.startPos.y}` : `${animationDefs?.[2]?.startPos.x} ${animationDefs?.[2]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[2]?.endPos.x + 642 : animationDefs?.[2]?.endPos.x + 640}
            y={isLargeDesktop ? animationDefs?.[2]?.endPos.y + 50 : animationDefs?.[2]?.endPos.y + 60}
            clipPath="url(#clip-path-4)"
          />
          <image
            href={images[5]}
            width="260"
            ref={bubbleRefs[5]}
            className={styles.bubbleAnimatable}
            transform-origin={isLargeDesktop ? `${animationDefs?.[5]?.endPos.x} ${animationDefs?.[5]?.startPos.y}` : `${animationDefs?.[5]?.startPos.x} ${animationDefs?.[5]?.startPos.y}`}
            x={isLargeDesktop ? animationDefs?.[5]?.endPos.x - 10 : animationDefs?.[5]?.endPos.x}
            y={animationDefs?.[5]?.endPos.y - 565}
            clipPath="url(#clip-path)"
          />
        </svg>
      </div>
    </FadeInWhenVisible>
  )
}

export { Anim }
