import { useEffect, useState } from 'react'

export interface WindowObjectInterface {
  width: undefined | number
  height: undefined | number
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowObjectInterface>({
    width: undefined ,
    height: undefined ,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-inner-declarations
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener('resize', handleResize)
      handleResize()

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

export default useWindowSize