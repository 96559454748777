import { SbBlokData } from '@storyblok/react'
import { ISbRichtext } from 'storyblok-js-client'
import { CustomLink } from '../../components/CustomLink'
import { FadeInWhenVisible } from '../../components/FadeInWhenVisible'
import { Grid } from '../../components/Grid/Grid'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { IMAGE_FILTER } from '../../utils/detectWebPSupported'
import { getNodeText } from '../../utils/getNodeText'
import { leadingSlashIt } from '../../utils/leadingSlashIt'
import renderRichText from '../../utils/richTextResolver'
import { PureSliceComponent, withSlice } from '../SliceComponent'
import { Anim, TeamBubble } from './Anim'
import classes from './TeamCard.module.scss'

export type ContentLink = {
  cached_url: string
}

export type CTAProps = SbBlokData & {
  title: string
  subtitle: string
  link: ContentLink
}

export type TeamCardProps = SbBlokData & {
  heading?: ISbRichtext
  content?: ISbRichtext
  cta: CTAProps[]
  style?: string
  height?: number
  image_source: TeamBubble[] | []
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TeamCard: PureSliceComponent<TeamCardProps, { settings: any }> = ({ slice }) => {
  const { heading, content, cta, image_source } = slice

  const teamMembers = image_source?.map(entry => {
    return `${entry?.content?.media?.filename}${IMAGE_FILTER}`
  })

  const richTextHeading = heading ? renderRichText(heading) : ''
  const rawTextHeading = getNodeText(richTextHeading)

  const richTextContent = content ? renderRichText(content) : ''
  const rawTextContent = getNodeText(richTextContent)

  const mobile = useMediaQuery('(max-width: 1023px)')

  const renderTeamCardTitle = (title: string)=>{
    return (
      <FadeInWhenVisible animation={'easyFadeInStill'} delay={.5}>
        <h2
          className={classes['teamCard-panel__heading']}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </FadeInWhenVisible>
    )
  }

  return image_source?.length > 0 ? (
    <div className={classes.wrapper}>
      {mobile && (
        <div className={classes['teamCard-mobile-title']}>
          {renderTeamCardTitle(richTextHeading)}
        </div>
      )}

      <div className={classes['teams-wrapper']}>
        <Anim team={teamMembers} />
      </div>
      <Grid container
        className={classes.teamCard}
      >
        <section className={classes['teamCard-panel']}>
          <FadeInWhenVisible animation={'easyFadeInTwoColStaggered'} transition={{ duration: 0.5 }}>
            {(!mobile && rawTextHeading) ?
              renderTeamCardTitle(richTextHeading) : null}
          </FadeInWhenVisible>

          {rawTextContent ?
            <FadeInWhenVisible animation={'easyFadeInTwoColStaggered'} transition={{ duration: 0.5, delay: 0.5 }}>
              <div
                className={classes['teamCard-panel__content']}
                dangerouslySetInnerHTML={{ __html: richTextContent }}
              /></FadeInWhenVisible> : null}
          {cta?.[0] && cta.length > 0 ?

            <FadeInWhenVisible animation={'easyFadeInTwoColStaggered'} transition={{ duration: 0.5, delay: 1 }}>
              <CustomLink
                variant={'primary-with-arrow'}
                href={leadingSlashIt(cta?.[0]?.link.cached_url)}
                className={classes['teamCard__link']}
              >{cta?.[0]?.title}
              </CustomLink>
            </FadeInWhenVisible>
            : null}
        </section>
      </Grid>
    </div>
  ) : null
}

export default withSlice(TeamCard)
